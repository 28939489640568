<template>
  <div class="bg">
    <div class="wrapper">
      <div>
    <div _ngcontent-c9="" class="title">
      <h6 _ngcontent-c9="">培训服务</h6>
    </div>
    <div _ngcontent-c3="" class="content">
    <ul _ngcontent-c3="" class="show-list">
      <li @click="next(1)" _ngcontent-c3="" routerlink="/solution/1" routerlinkactive="active" tabindex="0" ng-reflect-router-link="/solution/1" ng-reflect-router-link-active="active">
        <img _ngcontent-c3="" alt="" src="../../assets/images/djt/dzfw.png">
        <p _ngcontent-c3="">定制培训服务介绍</p>
      </li>
      <li @click="next(2)" _ngcontent-c3="" routerlink="/solution/1" routerlinkactive="active" tabindex="0" ng-reflect-router-link="/solution/1" ng-reflect-router-link-active="active">
        <img _ngcontent-c3="" alt="" src="../../assets/images/djt/ptfw.png">
        <p _ngcontent-c3="">平台服务介绍</p>
      </li>
      <li @click="next(3)" _ngcontent-c3="" routerlink="/solution/1" routerlinkactive="active" tabindex="0" ng-reflect-router-link="/solution/1" ng-reflect-router-link-active="active">
        <img _ngcontent-c3="" alt="" src="../../assets/images/djt/zxfw.png">
        <p _ngcontent-c3="">在线咨询客服</p>
      </li>
    </ul>
  </div>
  </div>
    </div>
  </div>
  
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  setup() {
    const router = useRouter()
    const next = (type) => {
      router.push({ path: '/jtserve/detail' + type })
    }
    return {
      next
    }
  }
}
</script>

<style lang="less" scoped>
  .bg {
    background: #F7F6FA;
    padding: 60px 0;
    .activity {
      background: #fff;
      padding: 20px;
    }
  }
  .text-title {
    font-size: 24px;
    font-weight: bold;
    color:#333;
    text-align: center;
    margin-bottom: 18px;

  }
  .title {
    margin-right: 7px;
    font-size: 18px;
    color: #4F17A8;
    h6 {
      font-size: 22px;
  margin-right: 7px;

    }
  }
  .intro {
    text-indent: 2em;
  line-height: 40px;
  font-size: 16px;
  color: #333;

  }
.show-list{
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.show-list li{
  width: 25%;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.show-list li img {
  width: 250px;
  height: 250px;
  border: 1px solid #ccc;
}
.show-list li p {
  margin-top: 10px;
  font-size: 20px;
  text-align: center;
}
  
  
  
  </style>
